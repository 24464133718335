import React, { Component } from 'react';
import styled from 'styled-components';
import UiStateContext, { UiStateConsumer } from '../../context/UiStateContext';
import {
  ShoppingListStateContext,
  ShoppingListStateConsumer
} from '../../context/ShoppingListContext';
import ShoppingListItem from './ShoppingListItem';
import { breakpoints, colors } from '../../style-utilities/variables';
import ComponentStyles from '../../brand-styles/components/ShoppingList/ShoppingList.styles';

const StyledShoppingList = styled.div`
  border: 1px solid white;
  margin-bottom: 10px;
  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 50px;
  }
  .header {
    background: #ddd;
    display: flex;
    justify-content: flex-end;
    padding: 15px;

    .category {
      margin-right: auto;
    }
  }

  ul {
    margin: 0;
    padding-left: 0;
    list-style-position: inside;
    text-align: left;
    text-indent: -2em;
  }
  ${ComponentStyles};
`;

class ShoppingList extends Component {
  componentDidMount() {
    this.context.getItems();
  }

  render() {
    return (
      <UiStateConsumer>
        {uiStateContext => {
          return (
            <ShoppingListStateConsumer>
              {shoppingListContext => {
                const {
                  shoppingListItems,
                  toggleItemChecked,
                  deleteItem
                } = shoppingListContext;
                return (
                  <StyledShoppingList>
                    <ul id="listToPrint">
                      {shoppingListItems.map(item => {
                        return (
                          <ShoppingListItem
                            key={item.id}
                            item={item}
                            toggleItemChecked={toggleItemChecked}
                            deleteItem={deleteItem}
                            toggleModal={uiStateContext.toggleModal}
                          />
                        );
                      })}
                    </ul>
                  </StyledShoppingList>
                );
              }}
            </ShoppingListStateConsumer>
          );
        }}
      </UiStateConsumer>
    );
  }
}

ShoppingList.contextType = ShoppingListStateContext;

export default ShoppingList;
