import React, { Component } from 'react';
import styled from 'styled-components';
import { UserStateConsumer } from '../../context/UserStateContext';
import MainContent from './MainContent';
import UpdateUserForm from '../Forms/UpdateUserForm';
import TitleSection from './TitleSection';
import ComponentStyles from '../../brand-styles/components/UserProfile/UserProfile.styles';

const StyledUserProfile = styled.div`
  input,
  select,
  label {
    display: block;
    margin-bottom: 15px;
  }
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  ${ComponentStyles}
`;

const UserProfile = ({ user, updateUser, modalTriggers }) => {
  return (
    <MainContent>
      <StyledUserProfile className="mainContentUserProfile">
        <TitleSection title="My Profile" classes="center-text" />
        <UpdateUserForm
          user={user}
          updateUser={updateUser}
          modalTriggers={modalTriggers}
        />
      </StyledUserProfile>
    </MainContent>
  );
};

export default UserProfile;
