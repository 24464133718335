import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '../../style-utilities/variables';
import ShoppingListService from '../../services/shoppingList.service';
import UserService from '../../services/user.service';
import { validateEmail } from '../../utilities/validationUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faMinus,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

export const ShoppingListEmailStyle = styled.div`
  margin: 0 auto;

  h4 {
    color: ${colors.primary};
    font-size: 22px;
    margin-bottom: 8px;
  }

  .error {
    color: red;
  }

  .success {
    color: green;
  }

  .center {
    text-align: center;
  }

  .width {
    width: 60%;
  }

  .copy {
    font-size: 20px;
  }
`;

function validate(state) {
  const errors = {};

  if (!state.email) {
    errors.email = 'Email is required';
  } else if (state.email.length < 3) {
    errors.email = 'Email must be at least 3 characters long';
  } else if (!validateEmail(state.email)) {
    errors.email = 'Must be a valid email address';
  }
  return errors;
}

class ShoppingListNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.user.email,
      errors: {},
      hasBeenSubmitted: false,
      isSubmitting: false,
      success: false
    };

    this.shoppingListService = new ShoppingListService();
    this.userService = new UserService();
  }

  componentDidMount() {}

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () => {
        if (this.state.hasBeenSubmitted) {
          const errors = validate(this.state);
          this.setState({
            errors
          });
        }
      }
    );
  };

  send = () => {
    const errors = validate(this.state);
    if (Object.keys(errors).length) {
      this.setState({
        errors: errors,
        hasBeenSubmitted: true
      });
    } else {
      this.setState({
        errors: errors,
        hasBeenSubmitted: true,
        isSubmitting: true,
        success: false
      });

      const user = this.userService.getAuthenticatedUser();

      this.shoppingListService
        .emailShoppingListForUser(user, this.state.email)
        .then(() => {
          this.setState({
            errors: errors,
            hasBeenSubmitted: true,
            isSubmitting: false,
            success: true
          });
        });
    }
  };

  render() {
    return (
      <ShoppingListEmailStyle>
        <div className="container">
          <button onClick={this.props.close}>
            <FontAwesomeIcon className="back" icon={faArrowLeft} />
          </button>
          <div className="content center">
            <br />
            <br />
            <br />
            <div className="copy">Email your shopping list</div>
            <div className="copy">and tick off items</div>
            <div className="copy">in-store.</div>
            <br />
            <br />
            <div>
              <input
                className="width"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                // placeholder={this.props.user.email}
              />
              &nbsp;&nbsp;
              <button onClick={this.send} disabled={this.state.isSubmitting}>
                Send
              </button>
              {this.state.errors.email && (
                <p className="error">{this.state.errors.email}</p>
              )}
              {this.state.success && (
                <p className="success">
                  Success! An email with your shopping list has been sent.
                </p>
              )}
            </div>
          </div>
        </div>
      </ShoppingListEmailStyle>
    );
  }
}

export default ShoppingListNotes;
