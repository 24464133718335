import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { UserStateConsumer } from '../../context/UserStateContext';

import UpdateUserForm from '../Forms/UpdateUserForm';

import ComponentStyles from '../../brand-styles/components/UserProfile/Sidebar.styles';

const StyledSidebar = styled.aside`
  /* height: calc(
    100vh - 110px
  );  */
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 100 - 65px); */
  height: 100%;
  /* left: 0; */
  /* float: left; */
  /* overflow: scroll;
  -webkit-overflow-scrolling: touch; */
  /* position: fixed; */
  top: 65px;
  width: 300px;
  padding: 40px 15px;

  a {
    text-decoration: none;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    padding-top: 8px;
    a {
      text-decoration: none;
    }
  }
  ${ComponentStyles}
`;

const renderAvatar = user => {
  // checks for google auth profile object
  if (user !== null && user.auth_type === 'google') {
    return (
      <img
        style={{ borderRadius: '50%' }}
        src={user.profileObj.imageUrl || ''}
        alt="userPro"
      />
    );
  } else if (user !== null && user.auth_type === 'facebook') {
    return (
      <img
        style={{ borderRadius: '50%' }}
        src={user.picture.data.url || ''}
        alt="userPro"
      />
    );
  } else if (user !== null && user.auth_type === 'form') {
    return (
      <img
        style={{ borderRadius: '50%' }}
        src={user.avatar_urls['96'] || ''}
        alt="userPro"
      />
    );
  }
};

const Sidebar = ({ user, logout }) => {
  return (
    <StyledSidebar className="sidebar">
      {user !== null ? (
        <>
          <div className="img-container">{renderAvatar(user)}</div>
          <h2>
            {user.first_name} {user.last_name}
          </h2>
        </>
      ) : (
        <div>...Loading</div>
      )}
      <hr />
      <h3>My Account</h3>
      <ul>
        <li>
          <Link activeClassName="active" to="/user/profile">
            My Profile
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/user/lists">
            Saved Shopping List
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/user/favorites">
            Favorites
          </Link>
        </li>
        <li>
          <a
            href="#"
            className="active"
            onClick={e => {
              logout();
              e.preventDefault();
            }}
          >
            Sign Out
          </a>
        </li>
      </ul>
    </StyledSidebar>
  );
};

export default Sidebar;
