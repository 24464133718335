import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../src_american-beauty/style-utilities/variables';
import ComponentStyles from '../../brand-styles/components/ShoppingList/ShoppingListItem.styles';
const StyledShoppingListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 105%;
  list-style: none;
  padding: 15px;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid lightgray;
  margin-right: auto;

  .quantity {
    margin-right: 10px;
  }

  .unit {
    margin-right: 10px;
  }

  input[type='checkbox'] {
    margin-right: 15px;
  }
  button {
    border: none;
    font-size: 12px;
    margin-top: 4px;
    color: ${colors.primary};
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
  }
  svg {
    color: ${colors.primary};
  }

  /*CUSTOM SQUARE CHECKBOX  */
  .checkbox-button {
    cursor: pointer;
  }
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  label {
    margin-right: auto;
  }
  input {
    font-size: 1rem;
    line-height: 1.5;
    padding: 11px 23px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    outline: 0;
    background-color: transparent;
  }
  .checkbox-button__input {
    opacity: 0;
    position: absolute;
  }
  .checkbox-button__control {
    position: relative;
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-right: 12px;
    vertical-align: middle;
    background-color: inherit;
    color: black;
    border: 2px solid #666;
  }
  .checkbox-button__input:checked + .checkbox-button__control:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background-color: black;
  }
  .checkbox-button__input:checked + .checkbox-button__control {
    border-color: black;
  }
  .checkbox-button__control {
    transform: scale(0.75);
  }
  ${ComponentStyles}
`;

const ShoppingListItem = ({
  item,
  toggleItemChecked,
  toggleModal,
  deleteItem
}) => {
  return (
    <StyledShoppingListItem>
      <label className="checkbox-button">
        <input
          type="checkbox"
          // onChange={() => toggleItemChecked(item.id)}
          // checked={() => toggleItemChecked(item.id)}
          className="checkbox-button__input"
          id="choice1-1"
          name="choice1"
        />
        <span className="checkbox-button__control" />
        <span className="checkbox-button__label">
          {ReactHtmlParser(item.display)}
        </span>
      </label>
      <button
        className="edit-btn"
        onClick={() => {
          toggleModal('shoppinglistedit', {
            data: item
          });
        }}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </button>
      <button className="trash-btn" onClick={() => deleteItem(item)}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>
    </StyledShoppingListItem>
  );
};

export default ShoppingListItem;
