import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import MainContent from './MainContent';
import TitleSection from './TitleSection';
import ShoppingList from '../ShoppingList/ShoppingList';
import ShoppingListAdd from '../ShoppingList/ShoppingListAdd';
import ShoppingListNotes from '../ShoppingList/ShoppingListNotes';
import ShoppingListEmail from '../ShoppingList/ShoppingListEmail';

class ShoppingLists extends Component {
  state = {
    showEmail: false,
    viewPort: 'desktop',
    isBrowser: true
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }
  }

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.setViewport);
    }
  }

  setViewport = () => {
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
  };

  setShowEmail = value => {
    this.setState({
      showEmail: value
    });
  };

  printModal = e => {
    e.preventDefault();
    window.print();
  };

  render() {
    return (
      <MainContent>
        <TitleSection title="Shopping List">
          {this.state.viewPort === 'mobile' ? (
            <button onClick={this.setShowEmail.bind(this, true)}>Email</button>
          ) : null}
        </TitleSection>
        {this.state.showEmail ? (
          <ShoppingListEmail
            user={this.props.user}
            close={this.setShowEmail.bind(this, false)}
          />
        ) : (
          <div className="shopping-list">
            <ShoppingListAdd />
            <ShoppingList />
            <ShoppingListNotes />
            <div className="desktop-buttons">
              {this.state.viewPort === 'desktop' ? (
                <button
                  className="button"
                  onClick={this.setShowEmail.bind(this, true)}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  &nbsp; Email Shopping List
                </button>
              ) : null}

              {this.state.viewPort === 'desktop' ? (
                <button className="button" onClick={this.printModal}>
                  <FontAwesomeIcon icon={faPrint} />
                  &nbsp; Print Shopping List
                </button>
              ) : null}
            </div>
          </div>
        )}
      </MainContent>
    );
  }
}

export default ShoppingLists;
