import { css } from 'styled-components';

export default css`
  .favorites-container {
    width: 320px;
    text-align: center;
    margin: 0 auto;

    .RecipeCard {
      margin: 10px 20px 20px 10px !important;
    }
    @media (min-width: 1000px) {
      margin-left: 0;
      width: 800px;
      text-align: left;
    }
    @media (min-width: 1200px) {
      width: 942px;
    }
    @media (min-width: 1559px) {
      width: 1350px;

      margin: 0 auto;
    }
  }
`;
