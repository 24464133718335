import React, { Component } from 'react';
import MainContent from './MainContent';
import TitleSection from './TitleSection';
import RecipeCard from '../RecipeCard';
import FavoritesService from '../../services/favorites.service';
import UserService from '../../services/user.service';
import placeHolderImage from '../../images/placeholder.png';

class FavoritesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favorites: [],
      hideRemoveAll: false,
      mainIngredientTaxonomy: []
    };

    this.favoriteService = new FavoritesService();
    this.userService = new UserService();
  }

  componentDidMount() {
    this.getFavoritesForUser();
    this.favoriteService.fetchMainIngredientTaxonomy().then(items => {
      if (items) {
        this.setState({
          mainIngredientTaxonomy: items
        });
      }
    });
  }

  removeAllUserRecipies = () => {
    const user = this.userService.getAuthenticatedUser();
    if (this.state.favorites) {
      this.state.favorites.map(item => {
        this.favoriteService
          .toggleFavorite(user, item, 'recipes')
          .then(result => {
            this.getFavoritesForUser();
          });
      });
    }
  };

  getFavoritesForUser = () => {
    const user = this.userService.getAuthenticatedUser();

    if (user) {
      this.favoriteService
        .getFavoritesForUser(user, 'recipes')
        .then(favorites => {
          if (favorites) {
            this.props.userContext.updateFavoritesCount(favorites.length);
            this.setState({
              favorites
            });
          }
        });
    }
  };

  render() {
    const { favorites } = this.state;
    return (
      <MainContent>
        <TitleSection
          title={`Favorites (${favorites.length})`}
          classes="TitleSection"
        >
          <button
            style={{
              display: favorites.length > 0 ? 'block' : 'none'
            }}
            onClick={this.removeAllUserRecipies}
          >
            Remove All Recipes
          </button>
        </TitleSection>

        <div className="favorites-container">
          {favorites &&
            favorites.length > 0 &&
            favorites.map(recipe => {
              const mainIngredientSlugs = this.state.mainIngredientTaxonomy.filter(
                el => recipe.main_ingredient.some(e => e === el.id)
              );
              return (
                <RecipeCard
                  className="RecipeCard"
                  updateFavoritesCount={
                    this.props.userContext.updateFavoritesCount
                  }
                  key={recipe.slug}
                  imageUrl={
                    recipe.acf.thumbnail
                      ? recipe.acf.thumbnail.url
                      : placeHolderImage
                  }
                  cookTime={recipe.acf.total_time}
                  mainIngredient={
                    mainIngredientSlugs[0] ? mainIngredientSlugs[0].name : ''
                  }
                  title={recipe.title.rendered}
                  slug={recipe.slug}
                  onFavoriteCallback={this.getFavoritesForUser}
                />
              );
            })}
        </div>
      </MainContent>
    );
  }
}

export default FavoritesView;
