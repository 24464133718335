import { css } from 'styled-components';
import { colors, breakpoints } from '../../style-utilities/variables';

export default css`
  
  a {
    color: ${colors.bodyText};
  }
  width: 100%;

  & > div[role='group'] {
    width: 100%;
  }
  @media (max-width: ${breakpoints.generalDesktop}) {
    flex-direction: column;
  }
`;
