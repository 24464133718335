import { css } from 'styled-components';
import { colors, breakpoints, fonts } from '../../../style-utilities/variables';

export default css`
  background: ${colors.secondary};
  h2 {
    color: #fff;
  }
  h3 {
    color: #fff;
    font-weight: bold;
  }
  li a {
    color: #fff;
  }
  @media (-ms-high-contrast: none) {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  @media (max-width: 900px) {
    height: auto;
    width: 100.1%;
    padding-top: 1em;
    padding-bottom: 1em;
    position: relative;
    top: 0px;
    h2 {
      text-align: center;
      margin-top: 0;
    }
    h3 {
      display: none;
    }
    .img-container {
      padding: 15px 0;
      text-align: center;
    }
    ul {
      width: 100%;
      margin-top: 0;
      li {
        text-align: center;
      }
    }
  }
`;
