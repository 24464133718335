import { css } from 'styled-components';
import { breakpoints } from '../../../style-utilities/variables';
export default css`
  button {
    background: #fff;
  }
  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 10px;
  }
`;
