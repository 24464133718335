import React, { Component } from 'react';
import { Link } from 'gatsby';
import MaskedInput from 'react-maskedinput';
import { ErrorMessage, Field, Formik } from 'formik';
import RegularButton from '../RegularButton';
import moment from 'moment';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Forms/UpdateUserForm.styles';

const Div = styled.div`
  h3 {
    font-weight: bold;
  }
  .error {
    color: red;
    font-size: small;
    margin-bottom: 10px;
  }
  .success-notification {
    background-color: #fffbdd;
    color: #000;
    padding: 0.7rem;
    display: none;
  }
  input[type='checkbox'] {
    /* display: inline; */
    margin-right: 10px;
  }
  .label-checkbox .underline {
    text-decoration: underline;
  }
  a.underline {
    text-decoration: underline;
  }
  ${ComponentStyles}
`;
class UpdateUserForm extends Component {
  state = {
    user: this.props.user,
    showSuccessNotification: false,
    country: this.props.user.country || 'USA'
  };

  showSuccessNotification() {
    this.setState({
      showSuccessNotification: true
    });
    setTimeout(() => {
      this.setState({
        showSuccessNotification: false
      });
    }, 3000);
  }

  handleCountryChange(e) {
    this.setState({ country: e.target.value });
  }

  render() {
    const { user, country } = this.state;
    return (
      <Div>
        <div
          className="success-notification"
          style={{
            display: this.state.showSuccessNotification
              ? 'inline-block'
              : 'none'
          }}
        >
          Profile has been updated.
        </div>
        <p style={{ color: '#165adc', fontWeight: 'bold' }}>
          Fill out your information below to get started.
        </p>
        <h3>Personal Information</h3>
        <Formik
          initialValues={{
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            dob: user.meta.dob,
            country: user.meta.country || 'USA',
            street_address: user.meta.street_address,
            city: user.meta.city,
            state: user.meta.state,
            zip: user.meta.zip,
            newsletter: user.meta.newsletter,
            terms: user.meta.terms
          }}
          validate={values => {
            let errors = {};
            const namePattern = /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i;
            const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            const addressPattern = /^.{0,255}$/;
            const cityPattern = /^[A-Za-z\s.]{0,50}$/;
            const zipPattern = /^\d{5}$/;
            if (
              values.first_name.length &&
              !namePattern.test(values.first_name)
            ) {
              errors.first_name = 'Please enter your first name.';
            }
            if (
              values.last_name.length &&
              !namePattern.test(values.last_name)
            ) {
              errors.last_name = 'Please enter your last name.';
            }
            if (!values.email) {
              errors.email = 'Email address is required.';
            }
            if (!emailPattern.test(values.email)) {
              errors.email = 'Please enter a valid email address.';
            }
            const dob = moment(values.dob, 'MM-DD-YYYY');
            if (dob > moment()) {
              errors.dob = 'DOB cannot be in the future.';
            }
            if (
              values.street_address.length &&
              !addressPattern.test(values.street_address)
            ) {
              errors.street_address =
                'Street address cannot exceed 255 characters.';
            }
            if (values.city.length && !cityPattern.test(values.city)) {
              errors.city = 'Please enter a valid city.';
            }
            if (values.zip.length && !zipPattern.test(values.zip)) {
              errors.zip = 'Please provide a valid ZIP code.';
            }
            if (!values.terms) {
              errors.terms =
                'Please accept the terms & conditions in order to save changes.';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            user['first_name'] = values['first_name'];
            user['last_name'] = values['last_name'];
            user['meta'] = values;
            this.props.updateUser(user).finally(() => {
              setSubmitting(false);
              this.showSuccessNotification();
            });
          }}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <ErrorMessage
                name="first_name"
                component="div"
                className="error"
              />
              <Field
                name="first_name"
                placeholder="First Name"
                maxLength={20}
              />
              <ErrorMessage
                name="last_name"
                component="div"
                className="error"
              />
              <Field name="last_name" placeholder="Last Name" maxLength={20} />
              <ErrorMessage name="email" component="div" className="error" />
              <Field
                name="email"
                placeholder="Email Address"
                type="email"
                disabled={true}
              />
              <ErrorMessage name="dob" component="div" className="error" />
              <div>
                <Field
                  name="dob"
                  render={({ field }) => {
                    return (
                      <MaskedInput
                        mask="11/11/1111"
                        {...field}
                        placeholder="D.O.B mm/dd/yyyy"
                      />
                    );
                  }}
                />
              </div>
              <ErrorMessage name="country" component="div" className="error" />
              <div className="input-group country">
                <Field
                  component="select"
                  name="country"
                  onChange={e => {
                    props.handleChange(e);
                    this.handleCountryChange(e);
                  }}
                >
                  <option value="USA">USA</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Canada">Canada</option>
                </Field>
              </div>
              {'USA' === country ? (
                <div>
                  <ErrorMessage
                    name="street_address"
                    component="div"
                    className="error"
                  />
                  <Field
                    name="street_address"
                    placeholder="Street Address"
                    maxLength={255}
                  />
                  <ErrorMessage name="city" component="div" className="error" />
                  <Field name="city" placeholder="City" maxLength={50} />
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="error"
                  />
                  <div className="input-group state">
                    <Field component="select" name="state">
                      <option value="---" placeholder="State" />
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Guam">Guam</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Northern Marianas Islands">
                        Northern Marianas Islands
                      </option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </Field>
                  </div>
                  <ErrorMessage name="zip" component="div" className="error" />
                  <Field name="zip" placeholder="Zip Code" maxLength={5} />
                </div>
              ) : null}
              <div className="label-checkbox">
                <Field
                  component="input"
                  type="checkbox"
                  name="newsletter"
                  id="newsletter"
                  value={true}
                  defaultChecked={
                    this.state.user.meta.newsletter ? 'checked' : false
                  }
                />
                <div className="label">
                  <label htmlFor="newsletter" style={{ display: 'inline' }}>
                    Sign up for our weekly newsletter to get the latest recipes
                    and deals.
                  </label>
                </div>
              </div>
              <div className="label-checkbox">
                <ErrorMessage name="terms" component="div" className="error" />
                <Field
                  component="input"
                  type="checkbox"
                  name="terms"
                  id="terms"
                  value={true}
                  defaultChecked={
                    this.state.user.meta.terms ? 'checked' : false
                  }
                />
                <div className="label">
                  <label htmlFor="terms" style={{ display: 'inline' }}>
                    I agree to{' '}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="/terms-of-use"
                      id="ToC-link"
                      className="underline"
                    >
                      Terms and Conditions
                    </Link>
                  </label>
                </div>
              </div>
              <RegularButton type="submit" disabled={props.isSubmitting}>
                Save Changes
              </RegularButton>
            </form>
          )}
        />
        <br /> <br />
        <Link to="user/update-password" className="underline">
          Update Password
        </Link>
        <br /> <br />
      </Div>
    );
  }
}

export default UpdateUserForm;
