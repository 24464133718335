import React from 'react';
import PropTypes from 'prop-types';
import { UserStateConsumer } from '../context/UserStateContext';
import { Redirect } from '@reach/router';

const LoginRoute = ({ component: Component, location, ...rest }) => {
  const isBrowser = typeof window !== 'undefined';
  const isLoggedIn = isBrowser
    ? JSON.parse(localStorage.getItem('authenticatedUser')) !== null
    : false;

  if (isLoggedIn && location.pathname.includes(`login`)) {
    // If we’re not logged in, redirect to the home page.
    return <Redirect noThrow to="/user/profile" />;
  }

  return <Component {...rest} />;
};

LoginRoute.propTypes = {
  component: PropTypes.any.isRequired
};

export default LoginRoute;
