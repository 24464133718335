import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors, fonts } from '../../style-utilities/variables';
import ComponentStyles from '../../brand-styles/components/UserProfile/MainContent.styles';

const StyledMainContent = styled.div`
  min-height: calc(100vh - 110px);
  padding-top: 40px;
  padding-left: 15px;
  .favorites-container {
    text-align: center;
  }
  @media (min-width: ${breakpoints.tablet}) {
    .favorites-container {
      text-align: left;
    }
  }
  ${ComponentStyles}
`;

const MainContent = ({ children }) => {
  return <StyledMainContent>{children}</StyledMainContent>;
};

export default MainContent;
