import { css } from 'styled-components';
import { colors } from '../../../style-utilities/variables';
export default css`
  p {
    color: ${colors.bodyText} !important;
  }
  input {
    width: 80%;
  }
  #newsletter,
  #terms {
    width: 5%;
  }
  .label-checkbox {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    .error {
      position: absolute;
      top: 20px;
    }
  }
`;
