import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import RegularButton from '../RegularButton';
import { breakpoints, colors } from '../../style-utilities/variables';
import { ShoppingListStateConsumer } from '../../context/ShoppingListContext';
import ComponentStyles from '../../brand-styles/components/ShoppingList/ShoppingListAdd.styles';

const AddItemModal = styled.div`
  display: none;
  height: auto;
  padding: 20px;
  background: white;
  &.modal-open {
    display: block;
  }
  label {
    display: flex;
    margin: 10px 20px;
  }
  input,
  select {
    width: 90%;
    margin: 5px auto;
  }
  button {
    display: inline-block;
    margin: 20px 10px;
  }
`;

const Div = styled.div`
  background: white;
  padding: 20px 10px;
  transition: 0.3s right ease-out, 0.3s visibility ease-out;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  /* handle hiding the scroll bars:  */
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .main-list {
    text-align: center;
    h2 {
      color: ${colors.primary};
    }
    .add-shopping-list {
      width: 90%;
      margin: 0 auto;
      box-shadow: none;
      border: 1px solid lightgray;
      color: ${colors.primary};
      text-align: left;
      padding: 10px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    -webkit-overflow-scrolling: touch;

    ${AddItemModal} {
      padding: 5px;
      input,
      select {
        margin: 5px;
      }
      input,
      select {
        border: 1px solid black;
      }
      input {
        padding: 5px;
      }
      .modal-button {
        margin: 5px;
        padding: 5px 10px;
      }
    }
  }
  ${ComponentStyles};
`;

class ShoppingListAdd extends Component {
  state = {
    isAddItemOpen: false,
    ingName: '',
    qty: '',
    unit: 'units'
  };
  onChangeHandler = event => {
    const value = event.target.value;
    this.setState({ [event.target.name]: value });
  };
  openAddItemHandler = () => {
    this.setState({ isAddItemOpen: !this.state.isAddItemOpen });
  };

  closeModal = event => {
    event.preventDefault();
    this.setState({ isAddItemOpen: false });
  };

  onItemChangeHandler = event => {
    const value = event.target.value;
    this.setState({ [event.target.name]: value });
  };
  onQtyChangeHandler = event => {
    const value = event.target.value;
    // Regular Expression allows only integer, fraction or decimal numbers
    const regex = /^(\d+\.?\d*$)|^(\d+\/?\d*$)/g;
    let valInt = regex.test(value);
    valInt && valInt >= 0
      ? this.setState({ [event.target.name]: value })
      : this.setState({ [event.target.name]: '' });
  };

  addToShoppingList = (event, addFn) => {
    event.preventDefault();

    const name = this.state.ingName;

    if (name) {
      // const qty = parseInt(this.state.qty, 10) || 1;
      const qty = this.state.qty;
      const unit = this.state.unit;

      addFn([
        {
          name: name,
          quantity: qty,
          unit: unit
        }
      ]);
    }
    this.setState({ ingName: '', qty: '' });
  };

  render() {
    const openClass = classNames({
      'shop-open': true
    });
    const openModalClass = classNames({
      'modal-open': this.state.isAddItemOpen
    });

    return (
      <Div className={openClass}>
        <div className="main-list">
          <button
            className="add-shopping-list"
            onClick={this.openAddItemHandler}
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp; Add item to your list
          </button>
          <ShoppingListStateConsumer>
            {shoppingListContext => {
              return (
                <AddItemModal className={openModalClass}>
                  <h3>Add Ingredient to Shopping List</h3>
                  <form>
                    <input
                      type="text"
                      name="ingName"
                      placeholder="Name"
                      onChange={this.onItemChangeHandler}
                      value={this.state.ingName}
                    />
                    <input
                      type="text"
                      name="qty"
                      placeholder="Quantity"
                      onChange={this.onQtyChangeHandler}
                      value={this.state.qty}
                    />
                    <select name="unit" onChange={this.onChangeHandler}>
                      <option value="units">Units</option>
                      <option value="pounds">Pounds</option>
                      <option value="ounces">Ounces</option>
                    </select>
                    <RegularButton
                      className="modal-button"
                      onClick={this.closeModal}
                    >
                      Cancel
                    </RegularButton>
                    <RegularButton
                      className="modal-button"
                      onClick={e => {
                        this.addToShoppingList(e, shoppingListContext.addItems);
                      }}
                    >
                      Add
                    </RegularButton>
                  </form>
                </AddItemModal>
              );
            }}
          </ShoppingListStateConsumer>
        </div>
      </Div>
    );
  }
}

export default ShoppingListAdd;
