import React, { Component } from 'react';
import MainContent from './MainContent';
import { navigate } from 'gatsby';
import RegularButton from '../RegularButton';
import { Field, Formik, ErrorMessage } from 'formik';
import styled from 'styled-components';

const initialValues = {
  currentpassword: '',
  password: '',
  confirmpassword: ''
};

const Div = styled.div`
  .form {
    label,
    input,
      margin-bottom: 10px;
      width: 100%;
    }
    input {
      border: 1px solid #c2c2c2;
      padding: 12px;
      display: block;
    }
    .error {
      color: red;
      font-size: small;
      margin-bottom: 10px;
    }
    button {
      margin-bottom: 20px;
    }
  }
`;
class UpdatePasswordView extends Component {
  state = {
    user: this.props.user
  };

  render() {
    return (
      <MainContent>
        <Div>
          <h2>Update Password</h2>
          <p
            style={{
              display: this.props.userContext.passwordResetStatus
                ? 'block'
                : 'none'
            }}
          >
            Your password has been updated. It will take effect when you sign
            out.
          </p>
          <Formik
            initialValues={initialValues}
            validate={values => {
              let errors = {};
              if (!values.currentpassword.length) {
                errors.currentpassword = 'Current password is required.';
              }
              if (!/(?=.*[a-z])/.test(values.password)) {
                errors.password =
                  'Password must contain at least one (1) lowercase letter.';
              }
              if (!/(?=.*[A-Z])/.test(values.password)) {
                errors.password =
                  'Password must contain at least one (1) uppercase letter.';
              }
              if (!/.*[0-9]/.test(values.password)) {
                errors.password =
                  'Password must contain at least one (1) number.';
              }
              if (!/^.{8,255}$/.test(values.password)) {
                errors.password =
                  'Enter a password that is at least eight (8) characters in length.';
              }
              if (values.password !== values.confirmpassword) {
                errors.confirmpassword =
                  "New password and confirm password don't match.";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              delete values.currentpassword;
              const data = Object.assign(this.state.user, values);
              this.props.userContext.updatePassword(data).finally(() => {
                setSubmitting(false);
                resetForm(initialValues);
              });
            }}
            render={props => (
              <form onSubmit={props.handleSubmit}>
                <p>Current Password</p>
                <Field
                  type="password"
                  name="currentpassword"
                  placeholder="Enter your current password"
                />
                <ErrorMessage
                  name="currentpassword"
                  component="div"
                  className="error"
                />
                <p>New Password</p>
                <Field
                  type="password"
                  name="password"
                  placeholder="Enter your new password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
                <p>Confirm Password</p>
                <Field
                  type="password"
                  name="confirmpassword"
                  placeholder="Enter your new password"
                />
                <ErrorMessage
                  name="confirmpassword"
                  component="div"
                  className="error"
                />
                <RegularButton type="submit" disabled={props.isSubmitting}>
                  Update
                </RegularButton>
                <br />
                <button
                  onClick={() => {
                    navigate(`/user/profile`);
                  }}
                >
                  Cancel
                </button>
              </form>
            )}
          />
        </Div>
      </MainContent>
    );
  }
}

export default UpdatePasswordView;
