import React from 'react';
import { Router, Redirect } from '@reach/router';
import styled from 'styled-components';
// components
import { UserStateConsumer } from '../context/UserStateContext';
import { UiStateConsumer } from '../context/UiStateContext';
import LoginForm from '../components/Forms/LoginForm';
import UserProfile from '../components/UserProfile/UserProfile';
import PrivateRoute from '../components/PrivateRoute';
import LoginRoute from '../components/LoginRoute';
import Sidebar from '../components/UserProfile/Sidebar';
import FavoritesView from '../components/UserProfile/Favorites';
import ShoppingLists from '../components/UserProfile/ShoppingLists';
import UpdatePasswordView from '../components/UserProfile/UpdatePassword';
import ComponentStyles from '../brand-styles/pages/user.styles';
import { Helmet } from 'react-helmet';
import { capitalize } from '../utilities/stringUtils';

const StyledUserProfilePage = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  ${ComponentStyles};
`;

// used only for routing
const LoggedInView = ({ children }) => {
  const isBrowser = typeof window !== 'undefined';
  const isLoggedIn = isBrowser
    ? JSON.parse(localStorage.getItem('authenticatedUser')) !== null
    : false;

  if (isLoggedIn) {
    return <div>{children}</div>;
  } else {
    return null;
  }
};
const UserProfilePage = props => {
  // Function: To Remove hyphen Capitalize the first character after space
  const toTitleCase = str => {
    return str
      .toLowerCase()
      .replace(/(?:^|[\s-/])\w/g, function(match) {
        return match.toUpperCase();
      })
      .replace(/-/g, ' ');
  };
  return (
    <>
      <Helmet>
        <title>User Profile | {toTitleCase(props.siteTitle)} </title>
        <meta
          name="description"
          content={process.env.SITE_META_PROFILE_PAGE_DESCRIPTION}
        />
      </Helmet>
      <UiStateConsumer>
        {uiStateContext => {
          return (
            <UserStateConsumer>
              {userContext => {
                const { authenticatedUser: user, updateUser } = userContext;
                return (
                  <StyledUserProfilePage className="page-container">
                    <Router primary={false}>
                      <PrivateRoute
                        path="/user/*"
                        user={user}
                        component={Sidebar}
                        logout={userContext.logout}
                      />
                    </Router>
                    <Router>
                      <LoginRoute path="/user/login" component={LoginForm} />
                      <LoggedInView path="/user">
                        <UserProfile
                          path="/profile"
                          user={user}
                          updateUser={updateUser}
                          modalTriggers={uiStateContext.toggleModal}
                        />
                        <ShoppingLists user={user} path="/lists" />
                        <FavoritesView
                          path="/favorites"
                          userContext={userContext}
                        />
                        <UpdatePasswordView
                          path="/update-password"
                          userContext={userContext}
                          user={user}
                        />
                      </LoggedInView>
                    </Router>
                  </StyledUserProfilePage>
                );
              }}
            </UserStateConsumer>
          );
        }}
      </UiStateConsumer>
    </>
  );
};

export default UserProfilePage;
