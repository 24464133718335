import { get, post } from '../utilities/fetchUtils';

export default class NotesService {
  API_URL = `${process.env.RIVIANA_BACKEND_URI}/api/notes`;
  ACTIVE_BRAND = process.env.ACTIVE_BRAND;

  getNotes = async user => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;

    const url = `${this.API_URL}/${brand}/${userId}`;

    return get(url);
  };

  createNotes = async (user, text) => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;

    const payload = {
      brand,
      userId,
      text
    };

    return post(this.API_URL, payload);
  };
}
