import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import ComponentStyles from '../../brand-styles/components/UserProfile/TitleSection.styles';

const StyledTitleSection = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  h1 {
    margin-right: auto;
  }
  button {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
    border: none;
    background-color: transparent;
  }
  ${ComponentStyles}
`;

const TitleSection = ({ title, classes, children }) => {
  return (
    <>
      <StyledTitleSection className={classes}>
        <h1>{ReactHtmlParser(title)}</h1>
        {children}
      </StyledTitleSection>
      <hr />
    </>
  );
};

export default TitleSection;
