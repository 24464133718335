import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '../../style-utilities/variables';
import NotesService from '../../services/notes.service';
import UserService from '../../services/user.service';
import ComponentStyles from '../../brand-styles/components/ShoppingList/ShoppingListNotes.styles';

export const NoteSection = styled.div`
  margin-left: 0;
  width: 95%;
  h4 {
    color: ${colors.primary};
    font-size: 22px;
    margin-bottom: 8px;
  }
  textarea {
    height: 200px;
    width: 100%;
    border: 1px solid lightgray;
  }
  ${ComponentStyles}
`;

class ShoppingListNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: ''
    };

    this.notesService = new NotesService();
    this.userService = new UserService();
  }

  componentDidMount() {
    const user = this.userService.getAuthenticatedUser();

    if (user) {
      this.notesService.getNotes(user).then(notes => {
        if (notes) {
          this.setState({
            text: notes.text || ''
          });
        }
      });
    }
  }

  onChangeHandler = event => {
    const value = event.target.value;
    this.setState({ [event.target.name]: value });
  };

  onBlurHandler = event => {
    const user = this.userService.getAuthenticatedUser();

    if (user) {
      this.notesService.createNotes(user, this.state.text);
    }
  };

  render() {
    return (
      <NoteSection>
        <h4>Notes</h4>
        <textarea
          name="text"
          value={this.state.text}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
        />
      </NoteSection>
    );
  }
}

export default ShoppingListNotes;
